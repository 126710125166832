export const getAcreComparison = (acres: number) => {
    if (acres === 0) {
        return "nothing, good job!";
    } else if (acres < 2000) {
        return "Central Park";
    } else if (acres < 10000) {
        return "Tuvalu";
    } else if (acres < 50000) {
        return "Disney World";
    } else if (acres < 100000) {
        return "the Maldives";
    } else if (acres < 250000) {
        return "Redwood National Park";
    } else if (acres < 500000) {
        return "Sequoia National Park";
    } else if (acres < 1000000) {
        return "Yosemite National Park";
    } else if (acres < 2000000) {
        return "the Everglades";
    } else if (acres < 5000000) {
        return "Death Valley";
    } else if (acres < 10000000) {
        return "Belgium";
    } else if (acres < 20000000) {
        return "Latvia";
    } else if (acres < 30000000) {
        return "Iceland";
    } else {
        return "Greece";
    }
};
