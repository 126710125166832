import { MapProps } from "../types";
import { acresBurnedTable } from "./constants/fireImpactTable";

const calculateNewSeverity = (day: number, fireSeverity: number): number => {
    if (fireSeverity === 0) {
        return fireBegins(day) ? 1 : 0;
    } else {
        const intensityChange = fireIntensityChange(day);
        return Math.min(fireSeverity + intensityChange, 6);
    }
};

// TODO make home not get new fires
const fireBegins = (day: number): number => {
    const newFireChance = [
        0.01, 0.01, 0.01, 0.02, 0.02, 0.03, 0.03, 0.02, 0.01, 0,
    ];

    for (let i = 0; i < newFireChance.length; i++) {
        if (day <= (i + 1) * 10) {
            return Math.random() <= newFireChance[i] ? 1 : 0;
        }
    }

    throw new Error("Invalid day");
};

const fireIntensityChange = (day: number): number => {
    const fireWorsensChance = [
        0.25, 0.25, 0.3, 0.35, 0.35, 0.4, 0.3, 0.2, 0.1, 0.05,
    ];
    const fireImprovesChance = [
        0.3, 0.25, 0.2, 0.2, 0.2, 0.1, 0.05, 0.1, 0.5, 0.5,
    ];

    for (let i = 0; i < fireWorsensChance.length; i++) {
        if (day <= (i + 1) * 10) {
            const random = Math.random();
            if (random <= fireWorsensChance[i]) {
                return 1;
            }
            if (random > 1 - fireImprovesChance[i]) {
                return -1;
            }
            return 0;
        }
    }

    throw new Error("Invalid day");
};

const fightFire = (daysFightingFire: number, fireSeverity: number): number => {
    const effectiveness: number =
        fireFightEffectivenessTable[`counter${daysFightingFire}`][
            `severity${fireSeverity}`
        ];

    const successImpact = daysFightingFire >= 5 ? 2 : 1;
    if (Math.random() <= effectiveness) {
        return Math.max(fireSeverity - successImpact, 0);
    }
    return fireSeverity;
};

const calculateAcresBurned = (map: MapProps): number => {
    let newAcresBurned = 0;
    map.locations.forEach((location) => {
        newAcresBurned += acresBurnedTable[location.fireSeverity];
    });
    return newAcresBurned;
};

interface FireFightEffectivenessTable {
    [counter: string]: {
        [severity: string]: number;
    };
}

const fireFightEffectivenessTable: FireFightEffectivenessTable = {
    counter1: {
        severity0: 1,
        severity1: 0.9,
        severity2: 0.5,
        severity3: 0.2,
        severity4: 0.1,
        severity5: 0.05,
        severity6: 0.01,
    },
    counter2: {
        severity0: 1,
        severity1: 1,
        severity2: 0.7,
        severity3: 0.3,
        severity4: 0.15,
        severity5: 0.07,
        severity6: 0.02,
    },
    counter3: {
        severity0: 1,
        severity1: 1,
        severity2: 0.8,
        severity3: 0.7,
        severity4: 0.3,
        severity5: 0.15,
        severity6: 0.07,
    },
    counter4: {
        severity0: 1,
        severity1: 1,
        severity2: 0.9,
        severity3: 0.8,
        severity4: 0.7,
        severity5: 0.3,
        severity6: 0.15,
    },
    counter5: {
        severity0: 1,
        severity1: 1,
        severity2: 0.95,
        severity3: 0.9,
        severity4: 0.8,
        severity5: 0.7,
        severity6: 0.3,
    },
    counter6: {
        severity0: 1,
        severity1: 1,
        severity2: 1,
        severity3: 0.95,
        severity4: 0.9,
        severity5: 0.8,
        severity6: 0.7,
    },
};

export {
    calculateNewSeverity,
    fireBegins,
    fireIntensityChange,
    fightFire,
    calculateAcresBurned,
};
