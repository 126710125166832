import { useContext } from "react";
import { GameStateContext } from "../../GameStateContext";
import "./StatusText.scss";
import { getAcreComparison } from "../../helpers/getAcreComparison";

export const StatusText = () => {
    const context = useContext(GameStateContext);
    const acreComparison = getAcreComparison(context.acresBurned);
    return (
        <>
            <p className="status-text">
                This is day number. {context.turnCounter}
            </p>
            <p className="status-text">
                You've let {context.acresBurned.toLocaleString("en-US")} acres
                burn down. That's approximately the size of {acreComparison}.
            </p>
        </>
    );
};
