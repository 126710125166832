import { locationPermutations } from "./initializeGameState";
import { FGButtonActionType, FGControlButtonGroupProps } from "../types";
import { buttonLabels } from "./constants/buttonLabelsTable";

const setButtonGroups = () => {
    const buttonGroups: FGControlButtonGroupProps[] = [];
    for (const location in buttonLabels) {
        const buttonProps = createButtons(location);
        const buttonGroup = {
            location: location,
            buttonGroup: buttonProps,
        };
        buttonGroups.push(buttonGroup);
    }
    return buttonGroups;
};

export const buttonGroups = setButtonGroups();

function createButtons(currLocation: string) {
    const buttons = locationPermutations.map((payload, index) => ({
        label: buttonLabels[currLocation][index + 1],
        actionType:
            index === 0
                ? FGButtonActionType.FIGHTFIRE
                : FGButtonActionType.PERMUTE,
        payload: payload,
    }));
    return buttons;
}
