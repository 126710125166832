import React from "react";
import { LocationProps, LocationNames } from "../../types";
import "./Location.scss";

export const Location: React.FC<LocationProps> = ({
    name,
    fireSeverity,
    isCurrentLocation,
}) => {
    const flames = [];
    for (let i = 0; i < fireSeverity; i++) {
        flames.push(
            <img className="flame-img" src="/images/flame.svg" alt="flame" />
        );
    }
    return (
        <div className="location">
            <h2>{LocationNames[name]}</h2>
            <div className="flames">{flames}</div>
            <p>
                {isCurrentLocation && (
                    <img src="/images/firetruck.svg" alt="firetruck" />
                )}
            </p>
        </div>
    );
};
