import React, { useContext } from "react";
import { FGControlButtonProps, FGGameStateAction } from "../../types";
import { GameStateDispatchContext } from "../../GameStateContext";
import "./FGControlButton.scss";

export const FGControlButton: React.FC<FGControlButtonProps> = ({
    label,
    actionType,
    payload,
}) => {
    const dispatch = useContext(GameStateDispatchContext);
    const action: FGGameStateAction = {
        type: actionType,
        payload: payload,
    };
    const handleButtonClick = () => {
        dispatch(action);
    };

    return (
        <button className="fg-control-button" onClick={handleButtonClick}>
            {label}
        </button>
    );
};
