import { MapProps } from "../../types";
import { Location } from "./Location";
import "./Map.scss";

export const Map: React.FC<MapProps> = ({ locations }) => {
    return (
        <section className="map">
            {locations.map((location, index) => (
                <Location key={index} {...location} />
            ))}
        </section>
    );
};
