import { FGControlButtonContainer } from "./Control/FGControlButtonContainer";
import { HomepageLink } from "./Display/HomepageLink";
import { StatusPanel } from "./Display/StatusPanel";
import { Map } from "./Display/Map";
import { gameStateReducer } from "../reducers/gameStateReducer";
import { initialGameState } from "../helpers/initializeGameState";
import { useReducer } from "react";
import {
    GameStateContext,
    GameStateDispatchContext,
} from "../GameStateContext";
import "./Game.scss";

export const Game = () => {
    const [gameState, dispatch] = useReducer(
        gameStateReducer,
        initialGameState
    );
    return (
        <GameStateContext.Provider value={gameState}>
            <GameStateDispatchContext.Provider value={dispatch}>
                <div className="fg-game">
                    <HomepageLink />
                    <StatusPanel />
                    <Map locations={gameState.map.locations} />
                    <FGControlButtonContainer />
                </div>
            </GameStateDispatchContext.Provider>
        </GameStateContext.Provider>
    );
};
