import { FGControlButtonGroupProps, FGControlButtonProps } from "../../types";
import { FGControlButton } from "./FGControlButton";

export const FGControlButtonGroup: React.FC<FGControlButtonGroupProps> = ({
    buttonGroup,
}) => {
    return (
        <div className="fg-button-group">
            {buttonGroup.map(
                (buttonProps: FGControlButtonProps, index: number) => (
                    <FGControlButton
                        key={index}
                        label={buttonProps.label}
                        actionType={buttonProps.actionType}
                        payload={buttonProps.payload}
                    />
                )
            )}
        </div>
    );
};
