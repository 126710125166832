interface ButtonLabelTable {
    [location: string]: {
        [pathNumber: number]: string;
    };
}

export const buttonLabels: ButtonLabelTable = {
    home: {
        1: "Fight Fire",
        2: "North Bend Road",
        3: "Old Timber Road",
        4: "East Crater Road",
        5: "Short Cut 1",
        6: "Short Cut 2",
        7: "Bennets Pass",
    },
    rogue: {
        1: "Fight Fire",
        2: "South Bend Road",
        3: "Timber Trail",
        4: "South Forest Trail",
        5: "Grants Pass Road",
        6: "Rogue Valley Trail",
        7: "Lost Forestry Way",
    },
    umpqua: {
        1: "Fight Fire",
        2: "Old Myrtle Hwy",
        3: "Timber Pass 1",
        4: "Timber Pass 2",
        5: "Sawmill South",
        6: "Klamath Ridge Way",
        7: "Sasquatch Way",
    },
    freemont: {
        1: "Fight Fire",
        2: "Bly Way",
        3: "Old Bly Way",
        4: "Timber Ridge West",
        5: "Timber Road",
        6: "Paisley Way",
        7: "Main Way",
    },
    klamath: {
        1: "Fight Fire",
        2: "Timber Road",
        3: "Redwood Pass",
        4: "Timber Ridge Road",
        5: "Timber Pass Road",
        6: "Trinity Way",
        7: "Eureka Hwy",
    },
    cascade: {
        1: "Fight Fire",
        2: "Oregon Timber Road",
        3: "California Timber Pass",
        4: "Siskiyou Ridge Road",
        5: "Sawmill Road",
        6: "Sawmill Pass",
        7: "Yreka Hwy",
    },
    modoc: {
        1: "Fight Fire",
        2: "Ambrose Pierce Hwy",
        3: "Pines Pass",
        4: "Surprise Ridge Road",
        5: "Cedar Path",
        6: "Likely Home Road",
        7: "Hack a Way",
    },
};
