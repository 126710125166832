interface FireImpactTable {
    [severity: number]: number;
}

export const acresBurnedTable: FireImpactTable = {
    0: 0,
    1: 1000,
    2: 10000,
    3: 50000,
    4: 100000,
    5: 200000,
    6: 400000,
};
