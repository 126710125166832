import { MapProps } from "../types";
import { LocationProps } from "../types";
import { calculateNewSeverity } from "./fireDynamics";

export const calculateNewFires = (map: MapProps, day: number): MapProps => {
    const newLocations: LocationProps[] = map.locations.map(
        (location: LocationProps) => {
            if (location.isCurrentLocation || location.name === "home") {
                return location;
            } else {
                return {
                    ...location,
                    fireSeverity: calculateNewSeverity(
                        day,
                        location.fireSeverity
                    ),
                };
            }
        }
    );
    const newMap: MapProps = {
        locations: newLocations,
    };
    return newMap;
};
