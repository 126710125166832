export const generateShiftedArrays = (inputArray: number[]): number[][] => {
    const shiftedArrays: number[][] = [];
    const n = inputArray.length;

    for (let i = 0; i < n; i++) {
        const shiftedArray: number[] = [];
        for (let j = 0; j < n; j++) {
            shiftedArray.push(inputArray[(j + i) % n]);
        }
        shiftedArrays.push(shiftedArray);
    }

    return shiftedArrays;
};
