import "./FGControlButtonContainer.scss";
import { buttonGroups } from "../../helpers/setButtonGroups";
import {
    FGControlButtonGroupProps,
    FGControlButtonContainerProps,
} from "../../types";
import { useContext } from "react";
import { GameStateContext } from "../../GameStateContext";
import { FGControlButtonGroup } from "./FGControlButtonGroup";

export const FGControlButtonContainer: React.FC<
    FGControlButtonContainerProps
> = () => {
    const gameState = useContext(GameStateContext);
    const buttonGroup = buttonGroups.find(
        (group: FGControlButtonGroupProps) =>
            group.location === gameState.currentLocation
    );

    return (
        <section className="fg-button-container">
            {gameState.turnCounter < 100 ? (
                buttonGroup && (
                    <FGControlButtonGroup
                        location={gameState.currentLocation}
                        buttonGroup={buttonGroup.buttonGroup}
                    />
                )
            ) : (
                <h2>Game over!</h2>
            )}
        </section>
    );
};
