import { FGGameState, MapProps } from "../types";
import { generateShiftedArrays } from "@firefighting-game/processing-module/helpers/generateShiftedArrays";

function initializeGameState() {
    const identityPermutation = [1, 2, 3, 4, 5, 6, 7];
    const shiftedPermutations = generateShiftedArrays(identityPermutation);
    const locationPermutations = [
        identityPermutation,
        ...shiftedPermutations.slice(1).sort(() => Math.random() - 0.5),
    ];
    const locationNames = [
        "home",
        "rogue",
        "umpqua",
        "freemont",
        "klamath",
        "cascade",
        "modoc",
    ];

    interface LocationNamePermutationMapping {
        [location: string]: string;
    }

    const locationNamePermutationMapping: LocationNamePermutationMapping = {};

    for (let i = 0; i < locationNames.length; i++) {
        const locationName = locationNames[i];
        const locationPermutation = JSON.stringify(locationPermutations[i]);
        locationNamePermutationMapping[locationPermutation] = locationName;
    }

    const initialMap: MapProps = {
        locations: locationNames.map((location) => ({
            name: location,
            fireSeverity: 0,
            isCurrentLocation: location === "home",
        })),
    };

    const initialGameState: FGGameState = {
        currentLocation: "home",
        currentPermutation: identityPermutation,
        daysInLocation: 1,
        turnCounter: 1,
        map: initialMap,
        acresBurned: 0,
    };

    return {
        locationPermutations,
        locationNamePermutationMapping,
        initialGameState,
        locationNames,
    };
}

export const {
    locationPermutations,
    locationNamePermutationMapping,
    initialGameState,
    locationNames,
} = initializeGameState();
