import { ControlButtonProps } from "@firefighting-game/control-module/types";
import { GameState } from "@firefighting-game/processing-module/types";

// Control
export interface FGControlButtonProps extends ControlButtonProps {
    label: string;
    actionType: FGButtonActionType;
    payload: number[];
}

export interface FGControlButtonGroupProps {
    location: string;
    buttonGroup: FGControlButtonProps[];
}

export interface FGControlButtonContainerProps {}

export enum FGButtonActionType {
    PERMUTE = "permute",
    FIGHTFIRE = "fightfire",
}

// Display
export interface MapProps {
    locations: LocationProps[];
}

export interface LocationProps {
    name: string;
    fireSeverity: number;
    isCurrentLocation: boolean;
}

// Processing
export interface FGGameState extends GameState {
    currentLocation: string;
    currentPermutation: number[];
    daysInLocation: number;
    turnCounter: number;
    map: MapProps;
    acresBurned: number;
}

export interface FGGameStateAction {
    type: FGButtonActionType;
    payload: number[];
}

export const LocationNames: { [location: string]: string } = {
    home: "Home",
    rogue: "Rogue Forest",
    umpqua: "Umpqua Forest",
    freemont: "Freemont Forest",
    klamath: "Klamath Forest",
    cascade: "Cascade Forest",
    modoc: "Modoc Forest",
};
